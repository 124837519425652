<template>
  <div class="set" v-if="avatar">
    <div class="main">
      <div class="heaedr">
        <div class="close" @click="closeView"></div>
      </div>
      <div class="cut">
        <vue-cropper ref="cropper" :img="option.img" :output-size="option.size" :output-type="option.outputType"
                     :info="true" :full="option.full" :fixed="fixed" :fixed-number="fixedNumber"
                     :can-move="option.canMove" :can-move-box="option.canMoveBox" :fixed-box="option.fixedBox"
                     :original="option.original"
                     :auto-crop="option.autoCrop" :auto-crop-width="option.autoCropWidth"
                     :auto-crop-height="option.autoCropHeight" :center-box="option.centerBox"
                     :high="option.high"
                     :max-img-size="option.max"></vue-cropper>
      </div>
      <div class="test-button">
        <button @click="changeImg" class="btn">清除图片</button>
        <label class="btn" for="uploads">上传图片</label>
        <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
               accept="image/png, image/jpeg, image/gif, image/jpg"
               @change="uploadImg($event, 1)">
        <a @click="down('base64')" class="btn">确定</a>
      </div>
      <div class="fin">上传大小限制1MB</div>
    </div>
  </div>

</template>

<script>
import {VueCropper} from "vue-cropper";
import {Dialog} from "vant";

export default {
  data() {
    return {
      avatar: false,
      model: false,
      modelSrc: '',
      crap: false,
      previews: {},
      lists: [
        {
          img: '',
        },
      ],
      option: {
        img: '',
        size: 1,
        outputSize:0.1,
        full: false,
        outputType: 'jpeg',
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        mode:'cover',
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 100,
        autoCropHeight: 100,
        centerBox: true,
        high: true,
        max: 99999,
      },
      show: true,
      fixed: true,
      fixedNumber: [1, 1],
    }
  },
  components: {VueCropper},
  methods: {
    openView() {
      this.avatar = true
    },
    closeView() {
      this.avatar = false
      this.lists[0].img = ''
      this.option.img = ''
    },
    changeImg() {
      this.option.img = this.lists[~~(Math.random() * this.lists.length)].img;
    },
    down() {
      this.$refs.cropper.getCropData((data) => {
        if (data) {
          this.$parent.selectImg(data)
          this.closeView()
        } else {
          Dialog.alert({
            message: '图片为空，请重试上传',
          }).then(() => {
            // on close
          });
        }
      });
    },

    uploadImg(e) {
      //上传图片
      let file = e.target.files[0];
      // this.option.img
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种');
        return false;
      }

      if (file.size/(1024*1024)>=1){
        Dialog.alert({
          message: '上传图片大小限制为1MB之内',
        })
        return ;
      }


      let reader = new FileReader();
      // console.log(reader)
      reader.onload = (k) => {
        let data;

        data = k.target.result
        this.option.img = data

      }
      reader.readAsDataURL(file)
      // reader.readAsDataURL(file)
      //   let data;
      //   if (typeof e.target.result === 'object') {
      //     // 把Array Buffer转化为blob 如果是base64不需要
      //     data = window.URL.createObjectURL(new Blob([e.target.result]));
      //   } else {
      //     data = e.target.result;
      //   }
      //   if (num === 1) {
      //     this.option.img = data;
      //   } else if (num === 2) {
      //     this.example2.img = data;
      //   }
      // };
      // // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      // reader.readAsArrayBuffer(file);
    }
  },
}
</script>

<style scoped lang="scss">

.set {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .main {
    width: 300px;
    height: 515px;
    background-color: rgba(249, 249, 249, 0.97);
    box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -257.5px;

    .heaedr {
      width: 100%;
      height: 30px;
      position: relative;

      .close {
        width: 13px;
        height: 13px;
        background-image: url("../assets/image/exhibit/close.png");
        background-size: 100% 100%;
        position: absolute;
        right: 10px;
        top: 8px;
      }
    }

    .cut {
      width: 250px;
      height: 400px;
      margin: 0 auto;
    }

    .test-button {
      text-align: center;
      display: flex;
      justify-content: space-around;
      margin-top: 17px;
    }

    .btn {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #973733;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0 0 0 0;
      padding: 9px 15px;
      font-size: 11px;
      border-radius: 4px;
      color: #fff;
      background-color: #973733;
      transition: all .2s ease;
      text-decoration: none;
      user-select: none;
    }

    .img {
      position: absolute;
      width: 1000px;
      height: 500px;
      background-color: #898989;
      left: 50%;
      margin-left: -135px;
      top: 30px;
    }

    .fin{
      width: 100%;
      height: 36px;
      line-height: 36px;
      color: #973733;
      text-indent: 1rem;
      font-size: 12px;
    }
  }
}
</style>
