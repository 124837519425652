<template>
  <div>
    <div class="nav">
      <div class="keyWords">
        <div class="block" v-bind:class="{ open: start=='我的' }" @click="startUp('我的')">我的评论</div>
        <div class="block" v-bind:class="{ open: start=='弹幕' }" @click="startUp('弹幕')">弹幕评论</div>
        <div class="block" v-bind:class="{ open: start==false }" @click="startUp('收到')">收到评论</div>
      </div>
    </div>
    <div class="notdata" v-if="data.length==0" v-bind:style="'height:'+height">暂无评论</div>
    <div class="list" v-bind:style="' line-height: 20px;height:'+height">
      <div class="sin"></div>
      <div v-if="start=='我的'">
        <div class="block" v-for="(item,index) in data" :key="index">
          <div class="basic">
            <div class="avatar"><img :src="item.avatar"></div>
            <div class="PenName">笔名：{{ item.name }}</div>
            <div class="Time">{{ item.newTime }}</div>
            <div class="sText">评论了这幅画作</div>
            <div class="bc">
              <img :src="item.imageUrl">
              <div class="titles">标题：{{ item.penName }}</div>
              <div class="number">编号：{{ item.sidNumber }}</div>
            </div>
          </div>
          <div class="text">{{ item.content }}</div>
          <div class="sin"></div>
        </div>
      </div>
      <div v-if="start==false">
        <div class="block" v-for="(item,index) in data" :key="index">
          <div class="basic">
            <div class="avatar"><img :src="item.avatar"></div>
            <div class="PenName">笔名：{{ item.name }}</div>
            <div class="Time">{{ item.newTime }}</div>
            <div class="sText">评论了这幅画作</div>
            <div class="bc">
              <img :src="item.imageUrl">
              <div class="titles">标题：{{ item.penName }}</div>
              <div class="number">编号：{{ item.sidNumber }}</div>
            </div>
          </div>
          <div class="text">{{ item.content }}</div>
          <div class="sin"></div>
        </div>
      </div>
      <div v-if="start=='弹幕'">
        <div class="blocks" v-for="(item,index) in data" :key="index">
          <div class="basic">
            <div class="time">{{ item.time }}</div>
            <div class="close" @click="commentclose(item._id)"></div>
          </div>
          <div class="data">{{ item.comment }}</div>
        </div>
      </div>
    </div>
    <loads ref="loads"></loads>
  </div>
</template>

<script>
import {initcomment, rmcomment, evaluateMy, evaluateOthers} from "../api/user";
import {Dialog, Notify} from "vant";
import loads from "./load";

export default {
  data() {
    return {
      data: [],
      height: '',
      start: '我的'
    }
  },
  components: {
    loads
  },
  mounted() {
    let height = document.documentElement.clientHeight - 40 - 150 - 41 - 5 - 45
    this.height = height + 'px'
    this.startUp(this.start)
  },
  methods: {
    startUp(str) {
      this.start = str
      switch (str) {
        case '弹幕':
          this.findcomment()
          break;
        case '我的':
          this.findMy()
          break;
        case '收到':
          Notify('功能未开放')
          // this.findOthers()
          break;

      }
    },
    // 他人评论
    findOthers() {
      let userIdCover = this.$store.state.userInfo._id
      this.data = []
      this.$refs.loads.open()
      evaluateOthers({userIdCover}).then(res => {
        this.$refs.loads.close()
        this.data = res.data
      })
    },
    //我的评论
    findMy() {
      let userId = this.$store.state.userInfo._id
      this.data = []
      this.$refs.loads.open()
      evaluateMy({userId}).then(res => {
        this.$refs.loads.close()
        let data = res.data
        for (let item in data) {
          let penName = data[item].penName
          let name = data[item].name
          let sidNumber = data[item].sidNumber
          if (penName.length >= 5) {
            data[item].penName = penName.slice(0, 4) + '...'
          } else {
            data[item].penName = penName
          }
          if (name.length >= 5) {
            data[item].name = name.slice(0, 4) + '...'
          } else {
            data[item].name = name
          }
          if (sidNumber.length >= 7) {
            data[item].sidNumber = sidNumber.slice(0, 7) + '...'
          } else {
            data[item].sidNumber = sidNumber
          }

        }
        this.data = data
      })
    },
    //弹幕评论
    findcomment() {
      this.$refs.loads.open()
      this.data = []
      let _id = this.$store.state.userInfo._id
      initcomment({_id}).then(res => {
        this.$refs.loads.close()
        if (res.code == 0) {
          let list = res.list
          this.data = list
        } else {
          Notify({type: 'warning', message: res.message});
        }
      })
    },
    //删除评论
    commentclose(_id) {
      Dialog.confirm({
        message: '是否删除该评论？',
      }).then(() => {
        rmcomment({_id}).then(res => {
          if (res.code == 0) {
            Notify({type: 'success', message: res.message});
            this.findcomment()
          } else {
            Notify({type: 'warning', message: res.message});
          }
        })
      }).catch(() => {
      });
    },
  }
}
</script>

<style scoped lang="scss">

.keyWords {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .block {
    font-size: 13px;
    color: #505050;
    background-color: #FFFFFF;
    border: 1px solid #f0f0f0;
    text-align: center;
    width: 50%;
    height: 30px;
    line-height: 30px;
    margin: 5px;
  }

  .open {
    background-color: #973733;
    color: #FFFFFF;
  }
}

.list::-webkit-scrollbar {
  display: none;
}

.list {
  width: 95%;
  margin: 0 auto;
  overflow-y: auto;

  .sin {
    width: 100%;
    height: 5px;
  }

  @mixin text {
    color: #666;
    font-size: 12px;
    position: absolute;
  }

  .block {
    width: 350px;
    height: auto;
    background-color: #FFFFFF;
    margin-bottom: 10px;

    .basic {
      width: 100%;
      height: 85px;
      position: relative;

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        position: absolute;
        left: 20px;
        top: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .PenName {
        @include text;
        left: 80px;
        top: 8px;
        width: 95px;
      }

      .Time {
        @include text;
        left: 80px;
        top: 35px;
        width: 95px;
      }

      .sText {
        @include text;
        left: 80px;
        top: 63px;
      }

      .bc {
        width: 160px;
        height: 73px;
        background-color: #f0f0f0;
        position: absolute;
        left: 178px;
        top: 7px;
        font-size: 12px;
        overflow: hidden;

        img {
          width: 45px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;

        }

        .titles {
          position: absolute;
          left: 55px;
          top: 11px;
          color: #202020;
          width: 103px;
          height: 25px;
        }

        .number {
          position: absolute;
          left: 55px;
          top: 35px;
          width: 103px;
          height: 40px;
          color: #202020;
          overflow: hidden;
          font-size: 12px;
        }
      }
    }

    .text {
      width: 90%;
      height: auto;
      margin: 0 auto;
      font-size: 12px;
    }

  }

  .blocks {
    width: 350px;
    height: auto;
    background-color: #FFFFFF;
    margin-bottom: 10px;

    .basic {
      width: 95%;
      height: 30px;
      margin: 0 auto;
      font-size: 12px;
      line-height: 30px;
      position: relative;

      .close {
        width: 20px;
        height: 20px;
        background-image: url("../assets/image/map/close.png");
        background-size: 100% 100%;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }

    .data {
      width: 95%;
      margin: 0 auto;
      font-size: 13px;
    }
  }

}

.notdata {
  width: 100%;
  height: 100%;
  color: #898989;
  text-align: center;
  font-size: 14px;

}
</style>
